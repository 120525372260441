.header {
    width: 86%;
    height: 86%;
    padding: 16px 32px !important;
}

.app-logo {
    width: 150px;
    height: 60px;
    object-fit: contain;
}