.pulsate {
    animation: pulse 1s infinite;

}

@keyframes pulse {
    0% {
        opacity: 80%;
    }

    50% {
        opacity: 50%;
    }

    100% {
        color: 80%;
    }
}