.language-select>div {
  display: flex;
  justify-content: center;
  align-items: center;
}


body {
  margin: 0;

}

.simplebar-scrollbar::before {
  opacity: 0.5;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}