.onbarding-input>input {
  height: 34px !important;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
}

.onbarding-input>div>fieldset {
  border-color: rgba(0, 0, 0, 0) !important;
}

.onbarding-input>.css-1rd2jtd-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #e01600 !important;
  border-width: 1px !important;
}

.onbarding-input>div>input {
  border-radius: 4px;
}

.onbarding-select>div {
  padding-left: 10px;
  padding-right: 10px;
  height: 34px !important;
  display: flex;
  align-items: center;
}

.box-error {
  margin-top: 1rem;
  padding: 1rem;
  background: var(--bg-error);
}

.box-success {
  margin-top: 1rem;
  padding: 1rem;
  background: var(--bg-success);
}

.img-loading {
  animation: rotationAnim 1.5s linear infinite;
}

@keyframes rotationAnim {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.code-verification-input>div>.ReactInputVerificationCode__item {
  width: 3.5rem !important;
  height: 3.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verification-input.small>.ReactInputVerificationCode__container {
  width: 100% !important;
}


.verification-input.small>.ReactInputVerificationCode__container>.ReactInputVerificationCode__item {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}