:root {
    --spot: #00183D;
    --jera: #4595cc;
    --worldline: #69bbaa;
    --uber: #000000;
    --dhl: #eac342;
    --payone: #0096D6;
}


[data-theme='light'],
:root.light {
    --border: var(--future-tax-8);
    --MuiPaper-root: var(--white);
    --Textfield-background: var(--future-tax-4);
    --Textfield-color: var(--future-tax-140);
    --Text-primary: var(--future-tax-110);
    --Text-disabled: var(--future-tax-40);
    --MuiDivider-root: var(--future-tax-8);
    --Background: var(--future-tax-4);
    --MuiButton-contained-disabled-bg: var(--future-tax-40);
    --MuiButton-contained-disabled-color: var(--future-tax-4);
    --MuiButton-contained-bg: var(--future-tax-100);
    --MuiButton-contained-color: var(--future-tax-0);
    --MuiButton-textPrimary: var(--future-tax-80);
    --ToggleButton-dark-bg: var(--future-tax-4);
    --ToggleButton-dark-text: #00000042;
    --ToggleButton-light-bg: var(--future-tax-0);
    --ToggleButton-light-text: var(--future-tax-100);
    --ToggleButton-border: var(--future-tax-8);
    --Mui-selected: var(--future-tax-4);
    --MuiFormHelperText-root: var(--error-100);
    --box-shadow-color: rgba(0, 0, 0, 0.08);
    --bg-error: var(--error-8);
    --bg-success: var(--success-20);
    --text-error: var(--error-100);
    --text-success: var(--success-100)
}

[data-theme='dark'],
:root.dark {
    --border: #1E2F4D;
    --MuiPaper-root: var(--future-tax-110);
    --Textfield-background: var(--future-tax-140);
    --Textfield-color: var(--future-tax-4);
    --Text-primary: var(--future-tax-4);
    --Text-disabled: var(--future-tax-80);
    --MuiDivider-root: var(--future-tax-90);
    --Background: var(--future-tax-140);
    --MuiButton-contained-disabled-bg: var(--future-tax-60);
    --MuiButton-contained-disabled-color: var(--future-tax-90);
    --MuiButton-contained-bg: var(--future-tax-8);
    --MuiButton-contained-color: var(--future-tax-100);
    --MuiButton-textPrimary: var(--future-tax-8);
    --ToggleButton-dark-bg: var(--future-tax-100);
    --ToggleButton-dark-text: var(--future-tax-20);
    --ToggleButton-light-bg: var(--future-tax-120);
    --ToggleButton-light-text: var(--future-tax-80);
    --ToggleButton-border: var(--future-tax-140);
    --Mui-selected: var(--future-tax-90);
    --MuiFormHelperText-root: var(--error-70);
    --box-shadow-color: rgba(255, 255, 255, 0.08);
    --bg-error: var(--error-120);
    --bg-success: var(--success-120);
    --text-error: var(--error-20);
    --text-success: var(--success-20);
}

@font-face {
    font-family: "Theinhardt";
    src: url(../fonts/Theinhardt-Italic.woff) format("woff"),
        url(../fonts/Theinhardt-Italic.woff2) format("woff2");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Theinhardt";
    src: url(../fonts/Theinhardt-Light.woff) format("woff"),
        url(../fonts/Theinhardt-Light.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Theinhardt";
    src: url(../fonts/Theinhardt-LightItalic.woff) format("woff"),
        url(../fonts/Theinhardt-LightItalic.woff2) format("woff2");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Theinhardt";
    src: url(../fonts/Theinhardt-Medium.woff) format("woff"),
        url(../fonts/Theinhardt-Medium.woff2) format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Theinhardt";
    src: url(../fonts/Theinhardt-MediumItalic.woff) format("woff"),
        url(../fonts/Theinhardt-MediumItalic.woff2) format("woff2");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Theinhardt";
    src: url(../fonts/Theinhardt-Regular.woff) format("woff"),
        url(../fonts/Theinhardt-Regular.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}

body {
    min-height: 100vh;
}

* {
    font-family: "Theinhardt" !important;
}

.ec-box,
.MuiList-root {
    background: var(--MuiPaper-root) !important;
}

.MuiMenuItem-root.Mui-selected {
    background-color: var(--Mui-selected) !important;
}

.desktop-main,
.mobile-main,
.login,
.header,
.MuiAppBar-colorPrimary,
main,
body {
    background: var(--Background) !important;
}

.MuiInput-input,
.MuiFilledInput-input {
    background: var(--Textfield-background) !important;
    color: var(--Textfield-color) !important;
    box-shadow: none !important;
}


.MuiInputBase-input.MuiFilledInput-input.Mui-disabled {
    -webkit-text-fill-color: var(--future-tax-70) !important;
}

.MuiButtonBase-root {
    text-transform: none !important;
}

.MuiButton-contained.Mui-disabled {
    background: var(--MuiButton-contained-disabled-bg) !important;
    color: var(--MuiButton-contained-disabled-color) !important;
}

.MuiButton-contained {
    background: var(--MuiButton-contained-bg) !important;
    color: var(--MuiButton-contained-color) !important;
}

.MuiDivider-root {
    background: var(--MuiDivider-root) !important;
    height: 1.5px !important;
    border: 0px !important;
}

.MuiButton-textPrimary {
    color: var(--MuiButton-textPrimary) !important;
}

.MuiInputBase-root>.material-icons,
.svg-icon {
    fill: var(--Text-primary) !important;
}

.toggle-dark,
.toggle-light {
    border: 2px solid var(--ToggleButton-border) !important;
    font-weight: 600 !important;
}

.toggle-dark {
    background-color: var(--ToggleButton-dark-bg) !important;
    color: var(--ToggleButton-dark-text) !important;
}

.toggle-light {
    background-color: var(--ToggleButton-light-bg) !important;
    color: var(--ToggleButton-light-text) !important;
}

main {
    flex-direction: column;
}

.card-option {
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 16px;
    border-radius: 4px;
    margin-left: 0px !important;
    background: var(--Background);
    border: 1px solid transparent;
    margin-right: 0px !important;
    transition: all 0.15s ease-in-out;
}

.card-option.active {
    border: 1px solid var(--Text-primary);
    box-shadow: var(--box-shadow-color) 0px 2px 8px 0px, var(--box-shadow-color) 0px -4px 20px 0px;
}

.MuiFormHelperText-root.Mui-error {
    color: var(--MuiFormHelperText-root) !important;
}

span.red {
    color: var(--error-80) !important
}

span.green {
    color: var(--success-80) !important
}

p,
span,
a,
h1,
h2,
h3,
h4,
h5,
li,
.MuiTypography-root {
    color: var(--Text-primary) !important;
    font-family: "Theinhardt" !important;
    line-height: 1.45 !important;
    letter-spacing: normal !important;
}

.ReactInputVerificationCode__item {
    color: var(--Text-primary) !important;

}

.icon-fill-primary {
    fill: var(--Text-primary);
}

.icon-stroke-primary {
    stroke: var(--Text-primary);
}

.text-error {
    color: var(--text-error) !important;
}

.text-success {
    color: var(--text-success) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
}